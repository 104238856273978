/* #region === Navigation Mega Dropdown === */
.navigationMegaDropdown {
  display: none;
  width: 100%;
  position: fixed;
  max-width: 1440px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 40px;
  padding: 40px 60px 0;
  /* top: 80px; */
  z-index: 11;
}
.navigationMegaDropdown--inner {
  display: none;
  gap: 24px;
  padding: 24px 32px;
  width: 100%;

  border-radius: 18px;
  border-bottom: 1px solid var(--neutral-30);
  background: var(--neutral-10);
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.12);
}
.navigationMegaDropdownBtn {
  height: 56px;
  width: 100%;
  display: flex;
  padding: 0 16px;
  align-items: center;
  gap: 16px;
  text-decoration: none;

  border-radius: 8px;
  border: 1px solid transparent;
  background: var(--neutral-10);

  color: var(--neutral-100);

  /* Body Small/Medium */
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}
.navigationMegaDropdownBtn:hover {
  border: 1px solid var(--primary-main);
  background: var(--primary-surface);
}
.navigationMegaDropdownBtn p {
  text-align: left;
  flex-grow: 1;
}
.navigationMegaDropdownIcon {
  width: 32px;
  height: 32px;
  padding: 8px;
  display: flex;
  border-radius: 6px;
  background: var(--neutral-30);
}
.navigationMegaDropdownBtn:hover .navigationMegaDropdownIcon {
  background: var(--primary-border);
}
.navigationMegaDropdownArrowIcon {
  display: none;
}
.navigationMegaDropdownBtn:hover .navigationMegaDropdownArrowIcon {
  display: flex;
}

@media (min-width: 992px) {
  .navigationMegaDropdown--inner {
    display: flex;
  }
}

@media (min-width: 1200px) {
  .navigationMegaDropdown {
    padding: 40px 120px 0;
  }
}
/* #endregion */
