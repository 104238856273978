.top-navigation {
  border-bottom: 1px solid var(--neutral-30);
  z-index: 9;
  background: #fff;
  width: 100%;
  position: fixed;
  top: 0;
}

.nav-content {
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  min-height: 64px;
  margin: auto;
  padding: 0 24px;
  display: flex;
}

.app-logo {
  width: 115px;
  height: 35px;
}

.link-navigation {
  display: none;
}

.link-item {
  color: var(--neutral-100);
  cursor: pointer;
  align-items: center;
  gap: 4px;
  padding: 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-decoration: none;
  display: flex;
}

.link-item .arrow-down {
  display: flex;
}

.link-item:hover, .link-item.active {
  color: var(--primary-main);
}

.link-item.active .arrow-down {
  rotate: 180deg;
}

.img {
  width: 100%;
  height: 100%;
}

.nav-group-button {
  display: none;
}

.btn {
  cursor: pointer;
  background: none;
  border: none;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0;
}

.btn.login {
  color: var(--neutral-100);
  border: 1px solid var(--Neutral-30, #e2e8f0);
  border-radius: 24px;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
  display: flex;
}

.btn.demo-request {
  color: var(--neutral-10);
  background: var(--primary-main);
  border-radius: 24px;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
  display: flex;
  box-shadow: 0 1px 2px #1018280d;
}

.btn-hamburger {
  display: flex;
}

@media (min-width: 992px) {
  .nav-content {
    padding: 0 32px;
  }

  .link-navigation {
    align-items: center;
    gap: 16px;
    display: flex;
  }

  .nav-group-button {
    align-items: center;
    gap: 8px;
    display: flex;
  }

  .btn-hamburger {
    display: none;
  }
}

@media (min-width: 1200px) {
  .nav-content {
    padding: 0 23px;
  }

  .link-navigation {
    gap: 32px;
  }
}

.navigationMegaDropdown {
  z-index: 11;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 40px 60px 0;
  display: none;
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
}

.navigationMegaDropdown--inner {
  border-bottom: 1px solid var(--neutral-30);
  background: var(--neutral-10);
  border-radius: 18px;
  gap: 24px;
  width: 100%;
  padding: 24px 32px;
  display: none;
  box-shadow: 0 2px 8px 1px #0000001f;
}

.navigationMegaDropdownBtn {
  background: var(--neutral-10);
  color: var(--neutral-100);
  border: 1px solid #0000;
  border-radius: 8px;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 56px;
  padding: 0 16px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-decoration: none;
  display: flex;
}

.navigationMegaDropdownBtn:hover {
  border: 1px solid var(--primary-main);
  background: var(--primary-surface);
}

.navigationMegaDropdownBtn p {
  text-align: left;
  flex-grow: 1;
}

.navigationMegaDropdownIcon {
  background: var(--neutral-30);
  border-radius: 6px;
  width: 32px;
  height: 32px;
  padding: 8px;
  display: flex;
}

.navigationMegaDropdownBtn:hover .navigationMegaDropdownIcon {
  background: var(--primary-border);
}

.navigationMegaDropdownArrowIcon {
  display: none;
}

.navigationMegaDropdownBtn:hover .navigationMegaDropdownArrowIcon {
  display: flex;
}

@media (min-width: 992px) {
  .navigationMegaDropdown--inner {
    display: flex;
  }
}

@media (min-width: 1200px) {
  .navigationMegaDropdown {
    padding: 40px 120px 0;
  }
}

.navigationDrawer {
  z-index: 10;
  background: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.navigationDrawer.hide {
  display: none;
}

.navigationDrawer--header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0 24px;
  display: flex;
}

.navigationDrawer--headerLogo {
  max-width: 108px;
  display: flex;
}

.btn.navigationDrawer--closeBtn {
  display: flex;
}

.navigationDrawer--content {
  flex-direction: column;
  gap: 24px;
  height: calc(100% - 64px);
  padding: 24px;
  display: flex;
  overflow-y: scroll;
}

.navigationDrawer--nav {
  flex-direction: column;
  display: flex;
}

.navigationDrawer--navItem {
  flex-direction: column;
  gap: 12px;
  padding: 12px 0;
  display: flex;
}

.navigationDrawer--navItem a, .navigationDrawer--navItem span {
  color: var(--neutral-100);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-decoration: none;
}

.navigationDrawer--navItem a:hover {
  color: var(--primary-main);
}

.navigationDrawer--navItemBtn {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navigationDrawer--navItemBtnArrow {
  display: flex;
}

.navigationDrawer--navItemExpanded {
  border-left: 1px solid var(--neutral-30);
  flex-direction: column;
  gap: 16px;
  padding-left: 12px;
  display: flex;
}

.navigationDrawer--navItemExpanded.hide {
  display: none;
}

.navigationDrawer--navItemExpanded a {
  color: var(--neutral-100);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.navigationDrawer--btn {
  flex-direction: column;
  gap: 8px;
  width: 100%;
  display: flex;
}

.navigationDrawer--btnLogin {
  color: var(--Neutral-100, #1e2432);
  border-radius: 24px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
  display: flex;
  border: 1px solid var(--neutral-30) !important;
  width: 100% !important;
  height: 40px !important;
  padding: 0 16px !important;
}

.navigationDrawer--btnDemo {
  color: var(--neutral-10);
  border-radius: 24px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-decoration: none;
  display: flex;
  box-shadow: 0 1px 2px #1018280d;
  border: 1px solid var(--primary-main) !important;
  background: var(--primary-main) !important;
  width: 100% !important;
  height: 40px !important;
  padding: 0 16px !important;
}

@media (min-width: 992px) {
  .navigationDrawer {
    display: none;
  }
}

.footer {
  background: var(--neutral-100);
}

.footer-content__top {
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 60px;
  max-width: 1440px;
  margin: auto;
  padding: 60px 24px;
  display: flex;
  position: relative;
}

.footer-content-top__left, .footer-content-top__right {
  width: 100%;
}

.btn-cookies {
  background: var(--neutral-80);
  border-radius: 1000px;
  width: 48px;
  height: 48px;
  padding: 14px;
  display: flex;
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.footer-content-top__right {
  flex-wrap: wrap;
  gap: 24px;
  display: flex;
}

.footer-content__bottom {
  border-top: 1px solid var(--neutral-80);
}

.footer-content-bottom__inner {
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 12px;
  max-width: 1440px;
  margin: auto;
  padding: 20px 24px;
  display: flex;
}

.footer-nav-group {
  width: 100%;
  max-width: 300px;
}

.footer-nav-title {
  color: var(--neutral-10);
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.footer-links {
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  display: flex;
}

.footer-link-item {
  color: var(--neutral-50);
  align-items: center;
  gap: 4px;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-decoration: none;
  display: flex;
}

.footer-link-item .caption-new {
  background: linear-gradient(98deg, #19e56466 3.25%, #0de7e766 96.39%);
  border-radius: 4px;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  height: 19px;
  padding: 0 4px;
  display: flex;
}

.footer-link-item .caption-new span {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(98deg, #19e564 3.25%, #0de7e7 96.39%);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.app-logo-footer {
  max-width: 131px;
  margin-bottom: 20px;
  display: flex;
}

.footer-description {
  color: var(--neutral-50);
  margin-bottom: 32px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.footer-social-media-links {
  align-items: center;
  gap: 24px;
  display: flex;
}

.footer-social-media-link-item {
  width: 24px;
  height: 24px;
  display: flex;
}

.footer-content-bottom__text-left {
  color: var(--Neutral-60, #6b7c94);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.footer-content-bottom__text-right {
  color: var(--Neutral-50, #94a3b8);
  text-align: right;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-decoration: none;
  display: flex;
}

@media (min-width: 992px) {
  .footer-content__top {
    flex-direction: row;
    padding: 80px 32px 148px;
  }

  .footer-content-top__left {
    max-width: 400px;
  }

  .footer-content-top__right {
    max-width: 496px;
  }

  .btn-cookies {
    width: 24px;
    height: 24px;
    padding: 2px;
    bottom: 64px;
    left: 16px;
    right: auto;
  }

  .footer-content-top__right {
    flex-flow: row;
    gap: 16px;
  }

  .footer-content-bottom__inner {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 32px;
  }

  .footer-nav-group {
    max-width: 154px;
  }
}

@media (min-width: 1200px) {
  .footer-content__top {
    padding: 80px 120px 148px;
  }

  .footer-content-top__left, .footer-content-top__right {
    max-width: 486px;
  }

  .btn-cookies {
    bottom: 16px;
    left: 16px;
  }

  .footer-content-top__right {
    gap: 24px;
  }

  .footer-content-bottom__inner {
    padding: 20px 120px;
  }

  .footer-nav-group {
    max-width: 146px;
  }
}

.preferencePrivacy-popupDetail {
  z-index: 100;
  width: 100%;
  height: 100%;
  padding: 32px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.preferencePrivacy-popupDetail.show {
  display: block;
}

.preferencePrivacy-popupDetailOverlay {
  background: var(--neutral-100);
  opacity: .24;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.preferencePrivacy-popupDetail::-webkit-scrollbar {
  display: none;
}

.preferencePrivacy-popupDetail--closeBtn {
  flex: none;
  width: 32px;
  height: 32px;
  display: flex;
}

.preferencePrivacy-popupDetailInner {
  background: var(--neutral-10);
  border-radius: 8px;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}

.preferencePrivacy-popupDetailHeader {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
  display: flex;
}

.preferencePrivacy-popupDetailHeaderContent {
  align-items: center;
  gap: 16px;
  display: flex;
}

.preferencePrivacy-popupDetailHeaderContent .img {
  width: 32px;
  height: 32px;
}

.preferencePrivacy-popupDetailHeaderContent p {
  color: var(--neutral-100);
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.preferencePrivacy-popupDetailDesc {
  color: var(--neutral-100);
  align-items: center;
  margin-bottom: 14px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: flex;
}

.preferencePrivacy-popupDetailGroupBtn {
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
  display: flex;
}

.preferencePrivacy-popupDetailOption {
  flex-direction: column;
  align-items: flex-end;
  gap: 32px;
  display: flex;
}

.preferencePrivacy-popupDetailOptionTitle {
  color: var(--neutral-100);
  align-self: flex-start;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.preferencePrivacy-popupDetailOptionActions {
  border: 2px solid #e2e8f0;
  border-radius: 10px;
  flex-direction: column;
  display: flex;
}

.preferencePrivacy-popupDetailOptionAction {
  border-bottom: 2px solid #e2e8f0;
  padding: 14px 20px;
}

.preferencePrivacy-popupDetailOptionAction:last-child {
  border-bottom: none;
}

.preferencePrivacy-popupDetailOptionActionTitle {
  color: var(--neutral-100);
  margin-bottom: 7px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.preferencePrivacy-popupDetailOptionActionFooter {
  justify-content: space-between;
  align-items: center;
  gap: 27px;
  display: flex;
}

.preferencePrivacy-popupDetailOptionActionFooterDesc {
  color: var(--neutral-70);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.preferencePrivacy-popupDetailOptionActionFooterCaption {
  color: var(--neutral-100);
  flex: none;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.preferencePrivacy-popupDetailOptionActionFooterSwitch {
  flex: none;
  width: 40px;
  height: 18px;
  display: inline-block;
  position: relative;
}

.preferencePrivacy-popupDetailOptionActionFooterSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.preferencePrivacy-popupDetailOptionActionFooterSwitchSlider {
  cursor: pointer;
  background-color: #6e7191;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.preferencePrivacy-popupDetailOptionActionFooterSwitchSlider:before {
  content: "";
  background-color: #fff;
  width: 15px;
  height: 15px;
  transition: all .4s;
  position: absolute;
  bottom: 1.75px;
  left: 2.5px;
}

input:checked + .preferencePrivacy-popupDetailOptionActionFooterSwitchSlider {
  background-color: #2196f3;
}

input:focus + .preferencePrivacy-popupDetailOptionActionFooterSwitchSlider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .preferencePrivacy-popupDetailOptionActionFooterSwitchSlider:before {
  transform: translateX(20px);
}

.preferencePrivacy-popupDetailOptionActionFooterSwitchSlider.round {
  border-radius: 34px;
}

.preferencePrivacy-popupDetailOptionActionFooterSwitchSlider.round:before {
  border-radius: 50%;
}

.btn--preferencePrivacy-popupDetailConfirmPreference {
  background: var(--primary-main);
  border-radius: 36px;
  flex-direction: row;
  align-items: center;
  height: 56px;
  padding: 0 24px;
  display: flex;
}

.btn--preferencePrivacy-popupDetailConfirmPreference p {
  color: var(--neutral-10);
  margin-right: auto;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

@media (min-width: 992px) {
  .preferencePrivacy-popupDetailInner {
    padding: 32px;
  }

  .preferencePrivacy-popupDetailOptionTitle {
    font-size: 24px;
  }

  .preferencePrivacy-popupDetailGroupBtn {
    flex-direction: row;
  }

  .preferencePrivacy-popupDetailHeaderContent p {
    font-size: 24px;
  }

  .btn--preferencePrivacy-popupDetailConfirmPreference p {
    font-size: 16px;
  }
}

.preferencePrivacy-popup {
  z-index: 100;
  width: 100%;
  padding: 0 24px 36px;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.preferencePrivacy-popup.show {
  display: block;
}

.preferencePrivacy-popup--inner {
  box-sizing: border-box;
  background: var(--neutral-10);
  border: 1px solid var(--neutral-30);
  border-radius: 8px;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  display: flex;
  box-shadow: 0 0 40px #0000001a;
}

.preferencePrivacy-popup--header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  display: flex;
}

.preferencePrivacy-popup--headerContent {
  align-items: center;
  gap: 16px;
  display: flex;
}

.preferencePrivacy-popup--headerContent .img {
  width: 32px;
  height: 32px;
}

.preferencePrivacy-popup--headerContent p {
  color: var(--neutral-100);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.preferencePrivacy-popup--closeBtn {
  width: 32px;
  height: 32px;
}

.preferencePrivacy-popup--desc {
  color: var(--neutral-60);
  margin-bottom: 15px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.preferencePrivacy-popup--desc strong {
  color: var(--neutral-100);
  font-weight: 700;
}

.preferencePrivacy-popup--desc a {
  color: var(--primary-main);
}

.preferencePrivacy-popup--groupBtn {
  flex-direction: column-reverse;
  align-items: start;
  gap: 16px;
  width: 100%;
  display: flex;
}

.btn--rejectAllCookies, .btn--allowAllCookies {
  background: var(--primary-main);
  color: var(--neutral-10);
  border-radius: 36px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  max-width: 327px;
  height: 48px;
  padding: 0 24px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  display: flex;
}

.btn--rejectAllCookies {
  border: 1px solid var(--primary-main);
  color: var(--primary-main);
  background: none;
  border-radius: 36px;
}

@media (min-width: 992px) {
  .preferencePrivacy-popup {
    padding: 0 120px 64px;
  }

  .preferencePrivacy-popup--inner {
    padding: 15px;
  }

  .preferencePrivacy-popup--groupBtn {
    flex-direction: row;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
  }

  .btn--rejectAllCookies, .btn--allowAllCookies {
    width: -moz-fit-content;
    width: fit-content;
    height: 56px;
  }

  .preferencePrivacy-popup--headerContent p {
    font-size: 24px;
  }
}

.cookies-alert-popup {
  z-index: 100;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
}

.cookies-alert-overlay {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: #1e2432bf;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cookies-alert {
  background: var(--neutral-100);
  border-top: 1px solid var(--neutral-80);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.cookies-alert__inner {
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 24px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 24px;
  display: flex;
}

.cookies-alert__content {
  flex-direction: column;
  align-items: start;
  gap: 16px;
  display: flex;
}

.cookies-alert__logo-container {
  background: var(--neutral-80);
  border-radius: 1000px;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 2px;
  display: flex;
}

.cookies-alert__text {
  color: var(--neutral-50);
  max-width: 673px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.cookies-alert_group-btn {
  flex-direction: column-reverse;
  gap: 8px;
  width: 100%;
  display: flex;
}

.btn.cookies-learn-more-btn, .btn.cookies-accept-btn {
  width: 100%;
}

.cookies-learn-more-btn {
  color: var(--neutral-10);
  border: 1px solid #475569;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  padding: 0 16px;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  display: flex;
}

.cookies-accept-btn {
  background: var(--primary-main);
  color: var(--neutral-10);
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  min-height: 41px;
  padding: 0 16px;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  display: flex;
  box-shadow: 0 1px 2px #1018280d;
}

@media (min-width: 992px) {
  .cookies-alert__inner {
    flex-direction: row;
    align-items: center;
    gap: 0;
    padding: 20px 32px;
  }

  .cookies-alert__content {
    flex-direction: row;
    align-items: center;
  }

  .cookies-alert_group-btn {
    flex-direction: row;
    width: -moz-fit-content;
    width: fit-content;
  }

  .btn.cookies-learn-more-btn, .btn.cookies-accept-btn {
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media (min-width: 1200px) {
  .cookies-alert__inner {
    padding: 32px 120px;
  }

  .cookies-alert__logo-container {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
}

/*# sourceMappingURL=404.cbd2405a.css.map */
