/* #region === Pop Privacy Preference Center Detail === */
.preferencePrivacy-popupDetail {
  display: none;
  overflow-y: scroll;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 32px;
}
.preferencePrivacy-popupDetail.show {
  display: block;
}
.preferencePrivacy-popupDetailOverlay {
  /* Desktop - 1 */
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  /* Neutral / 100 */
  background: var(--neutral-100);
  opacity: 0.24;
}
.preferencePrivacy-popupDetail::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.preferencePrivacy-popupDetail--closeBtn {
  flex: none;
  display: flex;
  width: 32px;
  height: 32px;
}
.preferencePrivacy-popupDetailInner {
  position: relative;
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  background: var(--neutral-10);
  padding: 20px;
  border-radius: 8px;
}
.preferencePrivacy-popupDetailHeader {
  margin-bottom: 14px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.preferencePrivacy-popupDetailHeaderContent {
  display: flex;
  align-items: center;
  gap: 16px;
}
.preferencePrivacy-popupDetailHeaderContent .img {
  width: 32px;
  height: 32px;
}
.preferencePrivacy-popupDetailHeaderContent p {
  /* Mobile/Heading/H5 */
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 36px */

  /* Neutral / 100 */
  color: var(--neutral-100);
}

.preferencePrivacy-popupDetailDesc {
  margin-bottom: 14px;

  /* Body Small/Regular */
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */
  display: flex;
  align-items: center;

  /* Neutral / 100 */
  color: var(--neutral-100);
}
.preferencePrivacy-popupDetailGroupBtn {
  margin-bottom: 32px;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.preferencePrivacy-popupDetailOption {
  display: flex;
  gap: 32px;
  flex-direction: column;
  align-items: flex-end;
}
.preferencePrivacy-popupDetailOptionTitle {
  align-self: flex-start;
  /* Mobile/Heading/H5 */
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 36px */

  /* Neutral / 100 */
  color: var(--neutral-100);
}
.preferencePrivacy-popupDetailOptionActions {
  display: flex;
  flex-direction: column;

  /* Neutral/30 */
  border: 2px solid #e2e8f0;
  border-radius: 10px;
}
.preferencePrivacy-popupDetailOptionAction {
  padding: 14px 20px;
  border-bottom: 2px solid #e2e8f0;
}
.preferencePrivacy-popupDetailOptionAction:last-child {
  border-bottom: none;
}
.preferencePrivacy-popupDetailOptionActionTitle {
  margin-bottom: 7px;

  /* Body Small/Bold */
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;

  /* Neutral / 100 */
  color: var(--neutral-100);
}
.preferencePrivacy-popupDetailOptionActionFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 27px;
}
.preferencePrivacy-popupDetailOptionActionFooterDesc {
  /* Body Small/Medium */
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;

  /* Neutral/70 */
  color: var(--neutral-70);
}
.preferencePrivacy-popupDetailOptionActionFooterCaption {
  flex: none;

  /* Body Small/Bold */
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;

  /* Neutral / 100 */
  color: var(--neutral-100);
}

/* The switch - the box around the slider */
.preferencePrivacy-popupDetailOptionActionFooterSwitch {
  flex: none;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 18px;
}

/* Hide default HTML checkbox */
.preferencePrivacy-popupDetailOptionActionFooterSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.preferencePrivacy-popupDetailOptionActionFooterSwitchSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #6e7191;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.preferencePrivacy-popupDetailOptionActionFooterSwitchSlider:before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  left: 2.5px;
  bottom: 1.75px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .preferencePrivacy-popupDetailOptionActionFooterSwitchSlider {
  background-color: #2196f3;
}

input:focus + .preferencePrivacy-popupDetailOptionActionFooterSwitchSlider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked
  + .preferencePrivacy-popupDetailOptionActionFooterSwitchSlider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.preferencePrivacy-popupDetailOptionActionFooterSwitchSlider.round {
  border-radius: 34px;
}

.preferencePrivacy-popupDetailOptionActionFooterSwitchSlider.round:before {
  border-radius: 50%;
}

.btn--preferencePrivacy-popupDetailConfirmPreference {
  /* Button */
  height: 56px;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;

  /* Primary/Main 05A6F0 */
  background: var(--primary-main);
  border-radius: 36px;
}
.btn--preferencePrivacy-popupDetailConfirmPreference p {
  /* Body Medium/Semibold */
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Neutral/10 */
  color: var(--neutral-10);

  margin-right: auto;
}

@media (min-width: 992px) {
  .preferencePrivacy-popupDetailInner {
    padding: 32px;
  }
  .preferencePrivacy-popupDetailOptionTitle {
    font-size: 24px;
  }
  .preferencePrivacy-popupDetailGroupBtn {
    flex-direction: row;
  }
  .preferencePrivacy-popupDetailHeaderContent p {
    font-size: 24px;
  }
  .btn--preferencePrivacy-popupDetailConfirmPreference p {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
}
/* #endregion */

/* #region === Pop Privacy Preference Center === */
.preferencePrivacy-popup {
  padding: 0 24px 36px;

  display: none;
  position: fixed;
  z-index: 100;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
}
.preferencePrivacy-popup.show {
  display: block;
}
.preferencePrivacy-popup--inner {
  margin: auto;
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;

  max-width: 1200px;

  /* Neutral/10 */
  background: var(--neutral-10);
  /* Neutral/30 */
  border: 1px solid var(--neutral-30);
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.preferencePrivacy-popup--header {
  margin-bottom: 16px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.preferencePrivacy-popup--headerContent {
  display: flex;
  align-items: center;
  gap: 16px;
}
.preferencePrivacy-popup--headerContent .img {
  width: 32px;
  height: 32px;
}
.preferencePrivacy-popup--headerContent p {
  /* Mobile/Heading/H5 */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 36px */

  /* Neutral / 100 */
  color: var(--neutral-100);
}

.preferencePrivacy-popup--closeBtn {
  width: 32px;
  height: 32px;
}

.preferencePrivacy-popup--desc {
  /* Body Medium/Regular */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  /* Neutral/60 */
  color: var(--neutral-60);
  margin-bottom: 15px;
}
.preferencePrivacy-popup--desc strong {
  color: var(--neutral-100);
  font-weight: 700;
}
.preferencePrivacy-popup--desc a {
  color: var(--primary-main);
}

.preferencePrivacy-popup--groupBtn {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: start;
  gap: 16px;
}

.btn--rejectAllCookies,
.btn--allowAllCookies {
  /* Button */
  max-width: 327px;
  width: 100%;
  height: 48px;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  gap: 12px;

  /* Primary/Main 05A6F0*/
  background: var(--primary-main);
  border-radius: 36px;

  /* Body Medium/Semibold */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* Neutral/10 */
  color: var(--neutral-10);
}
.btn--rejectAllCookies {
  /* Primary/Main 05A6F0*/
  background: transparent;
  border: 1px solid var(--primary-main);
  border-radius: 36px;

  /* Primary/Main */
  color: var(--primary-main);
}

@media (min-width: 992px) {
  .preferencePrivacy-popup {
    padding: 0 120px 64px;
  }
  .preferencePrivacy-popup--inner {
    padding: 15px;
  }
  .preferencePrivacy-popup--groupBtn {
    width: fit-content;
    flex-direction: row;
    align-items: center;
  }
  .btn--rejectAllCookies,
  .btn--allowAllCookies {
    width: fit-content;
    height: 56px;
  }
  .preferencePrivacy-popup--headerContent p {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
}
/* #endregion */

/* #region === Cookies Alert === */
.cookies-alert-popup {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 100;
}
.cookies-alert-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(30, 36, 50, 0.75);
  backdrop-filter: blur(10px);
}
.cookies-alert {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--neutral-100);
  border-top: 1px solid var(--neutral-80);
}
.cookies-alert__inner {
  display: flex;
  gap: 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding: 24px;
  max-width: 1440px;
  margin: 0 auto;
}
.cookies-alert__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: start;
}
.cookies-alert__logo-container {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background: var(--neutral-80);
  border-radius: 1000px;

  flex: none;
}
.cookies-alert__text {
  max-width: 673px;

  /* Body Medium/Medium */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  /* Neutral / 50 */
  color: var(--neutral-50);
}
.cookies-alert_group-btn {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
}
.btn.cookies-learn-more-btn,
.btn.cookies-accept-btn {
  width: 100%;
}
.cookies-learn-more-btn {
  /* Body Small/Bold */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  /* Neutral/10 */
  color: var(--neutral-10);

  /* Auto layout */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;

  /* Neutral/70 */
  border: 1px solid #475569;
  border-radius: 24px;

  min-height: 40px;
}
.cookies-accept-btn {
  /* Auto layout */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;

  min-height: 41px;

  background: var(--primary-main);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 24px;

  /* Body Small/Bold */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;

  /* Neutral/10 */
  color: var(--neutral-10);
}

@media (min-width: 992px) {
  .cookies-alert__inner {
    padding: 20px 32px;
    flex-direction: row;
    align-items: center;
    gap: 0px;
  }
  .cookies-alert__content {
    flex-direction: row;
    align-items: center;
  }

  .cookies-alert_group-btn {
    width: fit-content;
    flex-direction: row;
  }
  .btn.cookies-learn-more-btn,
  .btn.cookies-accept-btn {
    width: fit-content;
  }
}

@media (min-width: 1200px) {
  .cookies-alert__inner {
    padding: 32px 120px;
  }
  .cookies-alert__logo-container {
    width: 40px;
    height: 40px;
    padding: 10px;
  }
}
/* #endregion */
