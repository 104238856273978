.navigationDrawer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background: white;
}
.navigationDrawer.hide {
  display: none;
}

.navigationDrawer--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  padding: 0 24px;
}
.navigationDrawer--headerLogo {
  display: flex;
  max-width: 108px;
}
.btn.navigationDrawer--closeBtn {
  display: flex;
}

.navigationDrawer--content {
  height: calc(100% - 64px);
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  overflow-y: scroll;
}

.navigationDrawer--nav {
  display: flex;
  flex-direction: column;
}
.navigationDrawer--navItem {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 0;
}
.navigationDrawer--navItem a,
.navigationDrawer--navItem span {
  color: var(--neutral-100);
  text-decoration: none;

  /* Body Small/Medium */
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}
.navigationDrawer--navItem a:hover {
  color: var(--primary-main);
}
.navigationDrawer--navItemBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
}
.navigationDrawer--navItemBtnArrow {
  display: flex;
}
.navigationDrawer--navItemExpanded {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 12px;

  border-left: 1px solid var(--neutral-30);
}
.navigationDrawer--navItemExpanded.hide {
  display: none;
}
.navigationDrawer--navItemExpanded a {
  color: var(--neutral-100);

  /* Body Small/Regular */
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.navigationDrawer--btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.navigationDrawer--btnLogin {
  text-decoration: none;
  width: 100% !important;
  display: flex;
  height: 40px !important;
  padding: 0 16px !important;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-radius: 24px;
  border: 1px solid var(--neutral-30) !important;

  color: var(--Neutral-100, #1e2432);

  /* Body Small/Bold */
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
}
.navigationDrawer--btnDemo {
  text-decoration: none;
  width: 100% !important;
  display: flex;
  height: 40px !important;
  padding: 0 16px !important;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border-radius: 24px;
  border: 1px solid var(--primary-main) !important;
  background: var(--primary-main) !important;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  color: var(--neutral-10);

  /* Body Small/Bold */
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */
}

@media (min-width: 992px) {
  .navigationDrawer {
    display: none;
  }
}

@media (min-width: 1200px) {
}
