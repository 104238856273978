/* #region --- Footer --- */
.footer {
  background: var(--neutral-100);
}
.footer-content__top {
  position: relative;
  max-width: 1440px;
  margin: auto;
  padding: 60px 24px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 60px;

  flex-direction: column;
}
.footer-content-top__left,
.footer-content-top__right {
  width: 100%;
}
.btn-cookies {
  display: flex;
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 48px;
  height: 48px;
  background: var(--neutral-80);
  border-radius: 1000px;
  padding: 14px;
}

.footer-content-top__right {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  /* flex-direction: column; */
}
.footer-content__bottom {
  border-top: 1px solid var(--neutral-80);
}
.footer-content-bottom__inner {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 20px 24px;
  max-width: 1440px;
  margin: auto;
  flex-direction: column;
  gap: 12px;
}
.footer-nav-group {
  width: 100%;
  max-width: 300px;
}
.footer-nav-title {
  color: var(--neutral-10);

  /* Body Medium/Semibold */
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}
.footer-links {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}
.footer-link-item {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--neutral-50);
  text-decoration: none;

  /* Body Small/Medium */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}
.footer-link-item .caption-new {
  display: flex;
  align-items: center;
  padding: 0px 4px;
  width: fit-content;
  height: 19px;
  border-radius: 4px;
  background: linear-gradient(
    98deg,
    rgba(25, 229, 100, 0.4) 3.25%,
    rgba(13, 231, 231, 0.4) 96.39%
  );
}
.footer-link-item .caption-new span {
  background: linear-gradient(98deg, #19e564 3.25%, #0de7e7 96.39%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 15px */
}
.app-logo-footer {
  display: flex;
  max-width: 131px;
  margin-bottom: 20px;
}

.footer-description {
  color: var(--neutral-50);
  margin-bottom: 32px;

  /* Body Medium/Medium */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}

.footer-social-media-links {
  display: flex;
  align-items: center;
  gap: 24px;
}
.footer-social-media-link-item {
  display: flex;
  width: 24px;
  height: 24px;
}
.footer-content-bottom__text-left {
  color: var(--Neutral-60, #6b7c94);

  /* Body Small/Medium */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}
.footer-content-bottom__text-right {
  color: var(--Neutral-50, #94a3b8);
  text-align: right;
  display: flex;
  text-decoration: none;

  /* Body Small/Medium */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}

@media (min-width: 992px) {
  .footer-content__top {
    padding: 80px 32px 148px;
    flex-direction: row;
  }

  .footer-content-top__left {
    max-width: 400px;
  }
  .footer-content-top__right {
    max-width: 496px;
  }

  .btn-cookies {
    bottom: 64px;
    left: 16px;
    right: auto;
    width: 24px;
    height: 24px;
    padding: 2px;
  }
  .footer-content-top__right {
    gap: 16px;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .footer-content-bottom__inner {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 32px;
  }

  .footer-nav-group {
    max-width: 154px;
  }
}

@media (min-width: 1200px) {
  .footer-content__top {
    padding: 80px 120px 148px;
  }
  .footer-content-top__left,
  .footer-content-top__right {
    max-width: 486px;
  }

  .btn-cookies {
    bottom: 16px;
    left: 16px;
  }

  .footer-content-top__right {
    gap: 24px;
  }
  .footer-content-bottom__inner {
    padding: 20px 120px;
  }

  .footer-nav-group {
    max-width: 146px;
  }
}
/* #endregion */
