/* #region --- Top Navigation --- */
.top-navigation {
    border-bottom: 1px solid var(--neutral-30);
    background: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
}
.nav-content {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  max-width: 1440px;
  margin: auto;
}

.app-logo {
  width: 115px;
  height: 35px;
}

.link-navigation {
  display: none;
}

.link-item {
  padding: 0px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--neutral-100);
  text-decoration: none;

  /* Body Small/Medium */
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
  cursor: pointer;
}
.link-item .arrow-down {
  display: flex;
}
.link-item:hover {
  color: var(--primary-main);
}
.link-item.active {
  color: var(--primary-main);
}
.link-item.active .arrow-down {
  rotate: 180deg;
}

.img {
  width: 100%;
  height: 100%;
}

.nav-group-button {
  display: none;
}

.btn {
  border: none;
  width: fit-content;
  height: fit-content;
  background: none;
  cursor: pointer;
  padding: 0;
}

.btn.login {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--neutral-100);

  /* Body Small/Bold */
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */

  border-radius: 24px;
  height: 40px;
  padding: 0 16px;
  border: 1px solid var(--Neutral-30, #e2e8f0);
}

.btn.demo-request {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--neutral-10);
  background: var(--primary-main);

  /* Body Small/Bold */
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 21px */

  border-radius: 24px;
  height: 40px;
  padding: 0 16px;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.btn-hamburger {
  display: flex;
}

@media (min-width: 992px) {
  .nav-content {
    padding: 0 32px;
  }
  .link-navigation {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .nav-group-button {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .btn-hamburger {
    display: none;
  }
}

@media (min-width: 1200px) {
  .nav-content {
    padding: 0 23px;
  }
  .link-navigation {
    gap: 32px;
  }
}
/* #endregion */

/* .main {
  max-width: 1440px;
  margin: auto;
} */
